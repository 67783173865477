<template>
      <section class="section-padding services-section home__card">
        <div class="container">
          <div class="services">
            <h2>{{ $t("ourServices") }}</h2>
            <div class="services-btn">
              <button class="right" @click="slidePrev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.90906 7.26521C9.50324 6.8906 8.87058 6.9159 8.49597 7.32172L5.2652 10.8217C4.9116 11.2047 4.9116 11.7952 5.26519 12.1782L8.49597 15.6783C8.87057 16.0841 9.50323 16.1094 9.90905 15.7348C10.3149 15.3602 10.3402 14.7276 9.96558 14.3217L8.28397 12.5L18 12.5C18.5523 12.5 19 12.0523 19 11.5C19 10.9477 18.5523 10.5 18 10.5L8.284 10.5L9.96557 8.67829C10.3402 8.27247 10.3149 7.63981 9.90906 7.26521Z"
                    fill="#777E91"
                  />
                </svg>
              </button>
              <button class="left" @click="slideNext">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.0909 7.26521C14.4968 6.8906 15.1294 6.9159 15.504 7.32172L18.7348 10.8217C19.0884 11.2047 19.0884 11.7952 18.7348 12.1782L15.504 15.6783C15.1294 16.0841 14.4968 16.1094 14.091 15.7348C13.6851 15.3602 13.6598 14.7276 14.0344 14.3217L15.716 12.5L6 12.5C5.44771 12.5 5 12.0523 5 11.5C5 10.9477 5.44771 10.5 6 10.5L15.716 10.5L14.0344 8.67829C13.6598 8.27247 13.6851 7.63981 14.0909 7.26521Z"
                    fill="#777E91"
                  />
                </svg>
              </button>
            </div>
          </div>
          <swiper
            :loop-fill-group-with-blank="true"
            :navigation="true"
            :pagination="{ clickable: true }"
            :slides-per-view="'auto'"
            :space-between="16"
            @swiper="onSwiper"
            ref="mySwiper"
          >
            <swiper-slide
              v-for="service in services"
              :key="service.id"
              class="swiper-slide-card"
            >
              <router-link :to="{ name: 'service', params: { slug: service.slug } }" class="cards">
                <div class="img">
                  <img v-if="service.featuredImage" :src="service.featuredImage.node.link" :alt="service.featuredImage.node.altText">
                  <img v-else src="../assets/default-photo.jpg" alt="Default Service Image">
                </div>
                <div class="text">
                  <span>{{ service.title }}</span>
                </div>
                <div class="text-info">
                  <span v-if="stripTags(service.excerpt).length > 0" v-html="stripTags(service.excerpt)"></span>
                  <span v-else>Заповніть будь ласка короткий заголовок, щоб тут відображався текст публікації</span>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>  
      </section>
  </template>
  
  <script>
  import axios from 'axios'
  import { servicesQuery } from '../graphql/services.query'
  import { useI18n } from "vue-i18n";
  import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
  import "swiper/css";
  import {
    computed,
  } from "vue";
  
  export default {
    name: "ServiceList",
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const { t } = useI18n();
      const swip = useSwiper;
      let swiper = null;
      const onSwiper = (s) => {
        swiper = s;
      };
      const slideNext = () => {
        if (swiper) {
          swiper.slideNext();
        }
      };
      const slidePrev = () => {
        if (swiper) {
          swiper.slidePrev();
        }
      };

      const slidesPerView = computed(() => {
        if (window.innerWidth < 420) {
          return 1;
        } else {
          return 4;
        }
      });

      return {
        slidesPerView,
        swiper,
        onSwiper,
        swip,
        slideNext,
        slidePrev,
        t,
      }
    },
    data() {
      return {
        services: []
      }
    },
    mounted() {
      axios.post('https://admin.ser-ezhik.com.ua/graphql', {
        query: servicesQuery
      })
        .then(response => {
          this.services = response.data.data.allServices.nodes
        })
        .catch(error => {
          console.error(error)
        })
    },
    methods: {
      stripTags(text) {
        return text.replace(/<p[^>]*>|<\/p>/g, '');
      }
    },
  }
  </script>
  <style lang="scss">
    .home__card {

      .cards {
        text-decoration: none;

        .img {

          img {
            width: 256px;
            height: 256px;
            object-fit: cover;
            border-radius: 24px;
            border: 1px solid #f6f6f6;
          }
        }
      }
    }
  </style>