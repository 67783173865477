<template>
  <section class="section-padding about-us-section section-container-old">
    <div class="container block-info">
      <div class="about-us">
        <h2 class="title">{{ $t("ourTitle") }}</h2>
        <p class="text">
          <span>{{ $t("ourText") }}</span>
          <span>{{ $t("ourText1") }}</span>
          <span>{{ $t("ourTextTime") }} </span>
        </p>
      </div>
      <div class="img-info">
        <img :src="require('../assets/Photo fix.jpg')" />
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "BlockInfo",
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.block-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  .about-us {
    display: flex;
    flex-direction: column;
    gap: 35px;
    .title {
      color: #23262f;
      font-family: "Manrope", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 30px;
      span {
        color: #72777a;
        font-family: "Manrope", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .block-info .img-info img {
    max-width: 448px;
  }
}
@media (max-width: 1190px) {
  .block-info .img-info img {
    max-width: 448px;
  }
}
@media (max-width: 950px) {
  .block-info {
    flex-direction: column;
  }
}

@media (max-width: 770px) {
  .block-info {
    .img-info img {
      max-width: 384px;
    }
  }
}
</style>
