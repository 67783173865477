<template>
    <div class="page-single-wrapper">
        <div class="page-single home__card">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <router-link to="/">Головна</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t("ourCars") }}</li>
                </ol>
            </nav>
            <h1>{{ $t("ourCars") }}</h1>
            <ul>
                <li
                    v-for="car in cars"
                    :key="car.id"
                    class="swiper-slide-card"
                >
                    <router-link :to="{ name: 'car', params: { slug: car.slug } }" class="cards">
                        <div class="img">
                            <img v-if="car.featuredImage" :src="car.featuredImage.node.link" :alt="car.featuredImage.node.altText">
                            <img v-else src="../assets/default-photo.jpg" alt="Default car Image">
                        </div>
                        <div class="text">
                            <span>{{ car.title }}</span>
                        </div>
                        <div class="text-info">
                            <span v-if="stripTags(car.excerpt).length > 0" v-html="stripTags(car.excerpt)"></span>
                            <span v-else>Заповніть будь ласка короткий заголовок, щоб тут відображався текст публікації</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>  
</template>

<script>
import axios from 'axios'
import { carsQuery } from "@/graphql/carsQuery";
import { useI18n } from "vue-i18n";

export default {
  name: "CarsView",
  setup() {
    const { t } = useI18n();

    return {
      t,
    }
  },
  data() {
    return {
      cars: []
    }
  },
  mounted() {
    axios.post('https://admin.ser-ezhik.com.ua/graphql', {
      query: carsQuery
    })
      .then(response => {
        this.cars = response.data.data.allCar.nodes
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    stripTags(text) {
      return text.replace(/<p[^>]*>|<\/p>/g, '');
    }
  },
}
</script>
<style lang="scss">
  .home__card {

    ul {
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        gap: 15px;

        .swiper-slide-card {
            // width: 25%;
            max-width: min-content;
        }
    }

    .cards {
      text-decoration: none;

      .img {

        img {
          width: 256px;
          height: 256px;
          object-fit: cover;
          border-radius: 24px;
          border: 1px solid #f6f6f6;
        }
      }
    }
  }
</style>