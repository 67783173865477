<template>
    <div class="page-single-wrapper">
        <div class="page-single">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <router-link to="/">Головна</router-link>
                    </li>
                    <li class="breadcrumb-item">
                    <router-link to="/cars">Наш автопарк</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ car.title }}</li>
                </ol>
            </nav>
            <h1>{{ car.title }}</h1>
            <div class="content-text-style" v-html="car.content"></div>
        </div>
    </div>  
</template>

<script>
import axios from 'axios'
import { carsQuery } from "@/graphql/carsQuery";

export default {
    name: "CarView",
    data() {
        return {
            car: {}
        };
    },
    mounted() {
        const slug = this.$route.params.slug;
        axios.post("https://admin.ser-ezhik.com.ua/graphql", {
            query: carsQuery,
            variables: { slug }
        })
            .then(response => {
            this.car = response.data.data.allCar.nodes.find(car => car.slug === slug);
        })
            .catch(error => {
            console.error(error);
        });
    }
}
</script>