<template>
  <div class="wrapper">
    <nav-menu></nav-menu>
    <div class="wrapper-sections">
      <router-view/>
    </div>
    <footer-loyaut></footer-loyaut>
  </div>
</template>
<script>
import NavMenu from './components/NavMenu.vue';
import FooterLoyaut from "./components/FooterLoyaut.vue";
export default {
  components:{
    NavMenu,
    FooterLoyaut
  }
}
</script>
<style lang="scss">
body {
  font-family: Manrope,sans-serif;
}
*{
  margin: 0;
  padding: 0;
}
.wrapper{
  // padding-left: 15px;
  // padding-right: 15px;
  // max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
}
.wrapper-sections {
  padding-top: 121px;
}

.section-container{
  padding-top: 40px;
  max-width: 1300px;
  padding-left: 140px;
  padding-bottom: 40px;
}

.page-single-wrapper {
  border-top: 1px solid #f2f4f5;

  .page-single {
    padding: 30px 15px;
    max-width: 1120px;
    margin: 0 auto;

    & > h1 {
      margin-bottom: 30px;
    }
  }
}

.section-padding {
  padding: 60px 0;
}

.content-text-style {
  // Global text styles
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    
  }

  // Specific element styles
  p {
    margin-bottom: 20px;
  }

  ul, ol {
    margin: 0 0 20px 20px;
    padding: 0;
    list-style: disc;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: #06c;
    &:hover {
      color: #09c;
    }
  }

  b, strong {
    font-weight: bold;
  }

  q, quote {
    font-style: italic;
  }

  .wp-block-image {

    &.size-large {
      width: 100%;
      height: 100%;
    }
  }
}

.breadcrumb {
  background-color: #feefe4;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  &-item {
    font-size: 16px;
    color: #666;
    margin-right: 15px;

    a {
      text-decoration: none;
      color: #000;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }      
    }

    & + &::before {
      content: "›";
      color: #ccc;
      margin-right: 10px;
    }

    &.active {
      color: #f58a1f;
      font-weight: bold;
      background-color: transparent !important;
    }
  }

  &-link {
    text-decoration: none;
    color: #337ab7;

    &:hover {
      color: #23527c;
    }
  }
}

.button {
  cursor: pointer;
  display: flex;
  width: 178px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: #23262f;
  border: 1px solid #23262f;
  color: #fcfcfd;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &.margin {
    margin: 35px auto 0;
  }
}


@media(max-width:1300px){
  .section-container{
    padding-left: 0px;
  }
}
@media(max-width:1190px){
  .section-container{
    margin: 0 auto;
  }
}
@media(max-width:770px){
  .wrapper-sections {
    padding-top: 71px;
  }
}

</style>
