<template>
    <div class="page-single-wrapper">
        <div class="page-single">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <router-link to="/">Головна</router-link>
                    </li>
                    <li class="breadcrumb-item">
                    <router-link to="/services">Послуги</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ service.title }}</li>
                </ol>
            </nav>
            <h1>{{ service.title }}</h1>
            <!-- <img v-if="service.featuredImage" :src="service.featuredImage.node.link" :alt="service.featuredImage.node.altText">
            <img v-else src="../assets/default-photo.jpg" alt="Default Service Image"> -->
            <div class="content-text-style" v-html="service.content"></div>
        </div>
    </div>  
</template>

<script>
import axios from 'axios'
import { servicesQuery } from '../graphql/services.query'

export default {
  name: "ServiceView",
  data() {
    return {
      service: {}
    }
  },
  mounted() {
    const slug = this.$route.params.slug
    axios.post('https://admin.ser-ezhik.com.ua/graphql', {
      query: servicesQuery,
      variables: { slug }
    })
      .then(response => {
        this.service = response.data.data.allServices.nodes.find(service => service.slug === slug)
      })
      .catch(error => {
        console.error(error)
      })
  }
}
</script>